export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')];

export const server_loads = [];

export const dictionary = {
	"/(app)": [4,[2]],
	"/(app)/box": [5,[2]],
	"/(app)/credit": [6,[2]],
	"/drop/[id]": [~14],
	"/(app)/game": [7,[2]],
	"/(app)/graveyard": [8,[2]],
	"/(guides)/guides": [12,[3]],
	"/(guides)/guides/[game]": [~13,[3]],
	"/(app)/new": [9,[2]],
	"/(app)/privacy-policy": [10,[2]],
	"/(app)/saves": [11,[2]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};